<template>
    <div class="qingwu">
        <div class="admin_main_block">

            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>设备管理</el-breadcrumb-item>
                    <el-breadcrumb-item>设备类型</el-breadcrumb-item>
                    <el-breadcrumb-item>新增设备类型</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <el-form ref="form" :model="form">
                <el-row :gutter="40">
                    <el-col :span="24">
                        <span class="grid-divider">基本信息</span>
                        <el-divider></el-divider>
                    </el-col>
                    <el-col :span="span" class="hight">
                        <el-form-item label="设备类型" prop="realName"
                                      :rules="[{required:true,message:'请输入设备类型名称',trigger: 'blur' },{min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}]">
                            <el-input placeholder="" v-model="form.realName" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span" class="hight">
                        <el-form-item label="设备型号" prop="idCardNo"
                                      :rules="[{required:true,message:'请输入设备型号',trigger: 'blur' },{min: 6, max: 50, message: '长度在 6 到 50 个字符', trigger: 'blur'}]">
                            <el-input placeholder="" v-model="form.idCardNo" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="span" class="hight">
                        <el-form-item label="设备押金" prop="realName"
                                      :rules="[{required:true,message:'请输入设备押金',trigger: 'blur' }]">
                            <el-input type="number" placeholder="" v-model="form.realName"
                                      :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span" class="hight">
                        <el-form-item label="上市日期" prop="idCardNo"
                                      :rules="[{required:true,message:'请选择上市日期',trigger: 'blur' }]">
                            <el-date-picker
                                    v-model="form.realName"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="span">
                        <el-form-item label="设备图片" prop="realName">
                            <upload @getUploadFile="getUploadFile" title="（尺寸：500x500）" field="card1"
                                    :param="upload.param"
                                    :urlPath="this.$isEmpty(form.idCardImage) ? form.idCardImage[0] : ''"></upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="24">
                        <span class="grid-divider">退押金信息</span>
                        <el-divider></el-divider>
                    </el-col>
                    <el-col :span="span" class="hight">
                        <el-form-item label="" prop="realName">
                            <el-row>
                                <el-col>
                                    <el-checkbox v-model="checked">截止日期</el-checkbox>
                                </el-col>
                                <el-col style="padding-left: 23px;">
                                    <el-date-picker
                                            v-model="form.realName"
                                            type="date"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-card>
                            <el-button icon="el-icon-check" type="primary" @click="back">
                                取消
                            </el-button>
                            <el-button icon="el-icon-back" type="primary" @click="success('form')">
                                提交
                            </el-button>
                        </el-card>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {baseUrl} from "@/plugins/api";
    import upload from "@/components/admin/upload.vue"

    export default {
        components: {
            upload,
        },
        data() {
            return {
                disabled: false,
                span: 6,
                actionUploadUrl: "",
                checked: true,
                form: {
                    idCardImage: [],
                },
                upload: {
                    title_card1: '身份证人像面',
                    title_card2: '',
                    title_card3: '',
                    param: {"fileType": 'identity'},
                },
            };
        },
        methods: {
            success(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            const url = this.$route.query.id ? this.$api.operationCenterUpdate : this.$api.operationCenterAdd;
                            this.post(url, this.form);
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消'
                            });
                        });
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '请填写完整信息！'
                        });
                        return false;
                    }
                });
            },
            back() {

            },
            post(data) {
                this.$post(this.$api, data).then((res) => {
                    if (res.code == 1) {
                        this.$parent.getList();
                    } else {
                        this.$message({
                            type: 'error',
                            message: '失败!'
                        });
                    }
                })
            },
            getUploadFile(val) {
                this.form.idCardImage[0] = val.url;
            },
        },
        created() {
            this.actionUploadUrl = baseUrl + "system/file/upload";
        },
    };
</script>
<style lang="scss" scoped>
    .el-card {
        text-align: right;
        margin-top: 30px;
    }

    .hight {
        height: 90px;
    }

    .el-select {
        width: 100%;
    }

    .el-input {
        width: 100%
    }

    .el-divider--horizontal {
        margin: 12px 0px 12px 0px;
    }
</style>
